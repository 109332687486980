/* istanbul ignore file */
/* tslint:disable */

export enum DeviceType {
  BEETLE_PRELIMINARY = 'BEETLE_PRELIMINARY',
  BEETLE_SCADA = 'BEETLE_SCADA',
  BEETLE_SLAM1 = 'BEETLE_SLAM1',
  BEETLE_T3 = 'BEETLE_T3',
  BEETLE_T4 = 'BEETLE_T4',
  BEETLE_T5 = 'BEETLE_T5',
  BEETLE_T6 = 'BEETLE_T6',
  BEETLE_VIRT = 'BEETLE_VIRT',
  CIT_SL = 'CIT_SL',
  CIT_XT = 'CIT_XT',
  CIT_XT_LAN = 'CIT_XT_LAN',
  DVR = 'DVR',
  IOT_BINARY = 'IOT_BINARY',
  IOT_BOSCH_TPS_110 = 'IOT_BOSCH_TPS_110',
  IOT_BUTTON = 'IOT_BUTTON',
  IOT_COMTAC_DI = 'IOT_COMTAC_DI',
  IOT_COMTAC_LD1 = 'IOT_COMTAC_LD1',
  IOT_ELSYS_EMS2_DESK = 'IOT_ELSYS_EMS2_DESK',
  IOT_ELSYS_ERS2 = 'IOT_ELSYS_ERS2',
  IOT_GATEWAY = 'IOT_GATEWAY',
  IOT_GENERIC = 'IOT_GENERIC',
  IOT_MULTICAL = 'IOT_MULTICAL',
  IOT_QUALITY_SENSOR = 'IOT_QUALITY_SENSOR',
  IOT_TEK_766 = 'IOT_TEK_766',
  IOT_THERMO_VALVE = 'IOT_THERMO_VALVE',
  IOT_THERMOSENSE_FRIGO = 'IOT_THERMOSENSE_FRIGO',
  IOT_THERMOSENSE_ONE_WIRE = 'IOT_THERMOSENSE_ONE_WIRE',
  IOT_THERMOSENSE_OUTDOOR = 'IOT_THERMOSENSE_OUTDOOR',
  IOT_THERMOSENSE_PT1000 = 'IOT_THERMOSENSE_PT1000',
  IOT_TRACY = 'IOT_TRACY',
  IOT_TRACY2 = 'IOT_TRACY2',
  IOT_WATER_LEAK_DETECTOR = 'IOT_WATER_LEAK_DETECTOR',
  IOT_WATER_METER = 'IOT_WATER_METER',
  IOT_WISELY_ALLSENSE = 'IOT_WISELY_ALLSENSE',
  IOT_WISELY_CARBONSENSE = 'IOT_WISELY_CARBONSENSE',
  IOT_WISELY_STANDARD = 'IOT_WISELY_STANDARD',
  ON_SITE_PANEL_SERVER = 'ON_SITE_PANEL_SERVER',
  TRACKER_FMC001 = 'TRACKER_FMC001',
  TRACKER_FMC640 = 'TRACKER_FMC640',
  UNKNOWN = 'UNKNOWN',
  VIRTUAL_DEVICE = 'VIRTUAL_DEVICE',
  WAGO_COMPACT_CONTROLLER = 'WAGO_COMPACT_CONTROLLER',
  WAGO_EDGE_CONTROLLER = 'WAGO_EDGE_CONTROLLER',
  WAGO_PFC = 'WAGO_PFC',
  WEATHER = 'WEATHER'
}
