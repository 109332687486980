import {translate} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {DeviceType} from 'api/entities';

export const DeviceTypeLabels = {
  get [DeviceType.BEETLE_PRELIMINARY]() {
    return translate(marker('DEVICE_TYPES.BEETLE_PRELIMINARY'));
  },
  get [DeviceType.BEETLE_SCADA]() {
    return translate(marker('DEVICE_TYPES.BEETLE_SCADA'));
  },
  get [DeviceType.BEETLE_SLAM1]() {
    return translate(marker('DEVICE_TYPES.BEETLE_SLAM1'));
  },
  get [DeviceType.BEETLE_T3]() {
    return translate(marker('DEVICE_TYPES.BEETLE_T3'));
  },
  get [DeviceType.BEETLE_T4]() {
    return translate(marker('DEVICE_TYPES.BEETLE_T4'));
  },
  get [DeviceType.BEETLE_T5]() {
    return translate(marker('DEVICE_TYPES.BEETLE_T5'));
  },
  get [DeviceType.BEETLE_T6]() {
    return translate(marker('DEVICE_TYPES.BEETLE_T6'));
  },
  get [DeviceType.BEETLE_VIRT]() {
    return translate(marker('DEVICE_TYPES.BEETLE_VIRT'));
  },
  get [DeviceType.CIT_SL]() {
    return translate(marker('DEVICE_TYPES.CIT_SL'));
  },
  get [DeviceType.CIT_XT]() {
    return translate(marker('DEVICE_TYPES.CIT_XT'));
  },
  get [DeviceType.CIT_XT_LAN]() {
    return translate(marker('DEVICE_TYPES.CIT_XT_LAN'));
  },
  get [DeviceType.DVR]() {
    return translate(marker('DEVICE_TYPES.DVR'));
  },
  get [DeviceType.IOT_BOSCH_TPS_110]() {
    return translate(marker('DEVICE_TYPES.IOT_BOSCH_TPS_110'));
  },
  get [DeviceType.IOT_BUTTON]() {
    return translate(marker('DEVICE_TYPES.IOT_BUTTON'));
  },
  get [DeviceType.IOT_BINARY]() {
    return translate(marker('DEVICE_TYPES.IOT_BINARY'));
  },
  get [DeviceType.IOT_COMTAC_DI]() {
    return translate(marker('DEVICE_TYPES.IOT_COMTAC_DI'));
  },
  get [DeviceType.IOT_COMTAC_LD1]() {
    return translate(marker('DEVICE_TYPES.IOT_COMTAC_LD1'));
  },
  get [DeviceType.IOT_ELSYS_ERS2]() {
    return translate(marker('DEVICE_TYPES.IOT_ELSYS_ERS2'));
  },
  get [DeviceType.IOT_ELSYS_EMS2_DESK]() {
    return translate(marker('DEVICE_TYPES.IOT_ELSYS_EMS2_DESK'));
  },
  get [DeviceType.IOT_GATEWAY]() {
    return translate(marker('DEVICE_TYPES.IOT_GATEWAY'));
  },
  get [DeviceType.IOT_GENERIC]() {
    return translate(marker('DEVICE_TYPES.IOT_GENERIC'));
  },
  get [DeviceType.IOT_MULTICAL]() {
    return translate(marker('DEVICE_TYPES.IOT_MULTICAL'));
  },
  get [DeviceType.IOT_TEK_766]() {
    return translate(marker('DEVICE_TYPES.IOT_TEK_766'));
  },
  get [DeviceType.IOT_QUALITY_SENSOR]() {
    return translate(marker('DEVICE_TYPES.IOT_QUALITY_SENSOR'));
  },
  get [DeviceType.IOT_THERMO_VALVE]() {
    return translate(marker('DEVICE_TYPES.IOT_THERMO_VALVE'));
  },
  get [DeviceType.IOT_THERMOSENSE_FRIGO]() {
    return translate(marker('DEVICE_TYPES.IOT_THERMOSENSE_FRIGO'));
  },
  get [DeviceType.IOT_THERMOSENSE_ONE_WIRE]() {
    return translate(marker('DEVICE_TYPES.IOT_THERMOSENSE_ONE_WIRE'));
  },
  get [DeviceType.IOT_THERMOSENSE_OUTDOOR]() {
    return translate(marker('DEVICE_TYPES.IOT_THERMOSENSE_OUTDOOR'));
  },
  get [DeviceType.IOT_THERMOSENSE_PT1000]() {
    return translate(marker('DEVICE_TYPES.IOT_THERMOSENSE_PT1000'));
  },
  get [DeviceType.IOT_TRACY]() {
    return translate(marker('DEVICE_TYPES.IOT_TRACY'));
  },
  get [DeviceType.IOT_TRACY2]() {
    return translate(marker('DEVICE_TYPES.IOT_TRACY2'));
  },
  get [DeviceType.IOT_WATER_METER]() {
    return translate(marker('DEVICE_TYPES.IOT_WATER_METER'));
  },
  get [DeviceType.IOT_WATER_LEAK_DETECTOR]() {
    return translate(marker('DEVICE_TYPES.IOT_WATER_LEAK_DETECTOR'));
  },
  get [DeviceType.IOT_WISELY_ALLSENSE]() {
    return translate(marker('DEVICE_TYPES.IOT_WISELY_ALLSENSE'));
  },
  get [DeviceType.IOT_WISELY_CARBONSENSE]() {
    return translate(marker('DEVICE_TYPES.IOT_WISELY_CARBONSENSE'));
  },
  get [DeviceType.IOT_WISELY_STANDARD]() {
    return translate(marker('DEVICE_TYPES.IOT_WISELY_STANDARD'));
  },
  get [DeviceType.ON_SITE_PANEL_SERVER]() {
    return translate(marker('DEVICE_TYPES.ON_SITE_PANEL_SERVER'));
  },
  get [DeviceType.TRACKER_FMC001]() {
    return translate(marker('DEVICE_TYPES.TRACKER_FMC001'));
  },
  get [DeviceType.TRACKER_FMC640]() {
    return translate(marker('DEVICE_TYPES.TRACKER_FMC640'));
  },
  get [DeviceType.UNKNOWN]() {
    return translate(marker('DEVICE_TYPES.UNKNOWN'));
  },
  get [DeviceType.VIRTUAL_DEVICE]() {
    return translate(marker('DEVICE_TYPES.VIRTUAL_DEVICE'));
  },
  get [DeviceType.WAGO_COMPACT_CONTROLLER]() {
    return translate(marker('DEVICE_TYPES.WAGO_COMPACT_CONTROLLER'));
  },
  get [DeviceType.WAGO_EDGE_CONTROLLER]() {
    return translate(marker('DEVICE_TYPES.WAGO_EDGE_CONTROLLER'));
  },
  get [DeviceType.WAGO_PFC]() {
    return translate(marker('DEVICE_TYPES.WAGO_PFC'));
  },
  get [DeviceType.WEATHER]() {
    return translate(marker('DEVICE_TYPES.WEATHER'));
  }
};
