/* eslint-disable max-lines */
import {translate} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {AlcedoTypes} from './alcedo-types.enum';
import {AlcedoUnit} from './alcedo-units.enum';

export interface AlcedoUnitDetails {
  id: number;
  label?: string;
  labelKey: string;
  symbol: string;
  alcedoTypeId: AlcedoTypes;
  multiplier?: number;
  offset?: number;
}

export const AlcedoUnits: Record<AlcedoUnit, AlcedoUnitDetails> = {
  [AlcedoUnit.UNIT_NOT_SET]: {
    id: AlcedoUnit.UNIT_NOT_SET,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.UNIT_NOT_SET'));
    },
    symbol: '',
    alcedoTypeId: AlcedoTypes.DOUBLE
  },
  [AlcedoUnit.SQUARE_METERS]: {
    id: AlcedoUnit.SQUARE_METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SQUARE_METERS'));
    },
    symbol: 'm²',
    alcedoTypeId: AlcedoTypes.AREA
  },
  [AlcedoUnit.SQUARE_FEET]: {
    id: AlcedoUnit.SQUARE_FEET,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SQUARE_FEET'));
    },
    symbol: 'ft²',
    alcedoTypeId: AlcedoTypes.AREA,
    multiplier: 0.09290303999999998
  },
  [AlcedoUnit.MILLIAMPERES]: {
    id: AlcedoUnit.MILLIAMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIAMPERES'));
    },
    symbol: 'mA',
    alcedoTypeId: AlcedoTypes.CURRENT,
    multiplier: 0.001
  },
  [AlcedoUnit.AMPERES]: {
    id: AlcedoUnit.AMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERES'));
    },
    symbol: 'A',
    alcedoTypeId: AlcedoTypes.CURRENT
  },
  [AlcedoUnit.OHMS]: {
    id: AlcedoUnit.OHMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.OHMS'));
    },
    symbol: 'Ω',
    alcedoTypeId: AlcedoTypes.RESISTANCE
  },
  [AlcedoUnit.VOLTS]: {
    id: AlcedoUnit.VOLTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLTS'));
    },
    symbol: 'V',
    alcedoTypeId: AlcedoTypes.VOLTAGE
  },
  [AlcedoUnit.KILOVOLTS]: {
    id: AlcedoUnit.KILOVOLTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOVOLTS'));
    },
    symbol: 'kV',
    alcedoTypeId: AlcedoTypes.VOLTAGE,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAVOLTS]: {
    id: AlcedoUnit.MEGAVOLTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAVOLTS'));
    },
    symbol: 'MV',
    alcedoTypeId: AlcedoTypes.VOLTAGE,
    multiplier: 1000000
  },
  [AlcedoUnit.VOLT_AMPERES]: {
    id: AlcedoUnit.VOLT_AMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLT_AMPERES'));
    },
    symbol: 'VA',
    alcedoTypeId: AlcedoTypes.POWER
  },
  [AlcedoUnit.KILOVOLT_AMPERES]: {
    id: AlcedoUnit.KILOVOLT_AMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOVOLT_AMPERES'));
    },
    symbol: 'kVA',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAVOLT_AMPERES]: {
    id: AlcedoUnit.MEGAVOLT_AMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAVOLT_AMPERES'));
    },
    symbol: 'MVA',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000000
  },
  [AlcedoUnit.VOLT_AMPERES_REACTIVE]: {
    id: AlcedoUnit.VOLT_AMPERES_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLT_AMPERES_REACTIVE'));
    },
    symbol: 'var',
    alcedoTypeId: AlcedoTypes.POWER
  },
  [AlcedoUnit.KILOVOLT_AMPERES_REACTIVE]: {
    id: AlcedoUnit.KILOVOLT_AMPERES_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOVOLT_AMPERES_REACTIVE'));
    },
    symbol: 'kvar',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAVOLT_AMPERES_REACTIVE]: {
    id: AlcedoUnit.MEGAVOLT_AMPERES_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAVOLT_AMPERES_REACTIVE'));
    },
    symbol: 'Mvar',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000000
  },
  [AlcedoUnit.DEGREES_PHASE]: {
    id: AlcedoUnit.DEGREES_PHASE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_PHASE'));
    },
    symbol: '°',
    alcedoTypeId: AlcedoTypes.ANGLE,
    multiplier: 0.017453292519943295
  },
  [AlcedoUnit.POWER_FACTOR]: {
    id: AlcedoUnit.POWER_FACTOR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POWER_FACTOR'));
    },
    symbol: 'pf',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 100
  },
  [AlcedoUnit.JOULES]: {
    id: AlcedoUnit.JOULES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES'));
    },
    symbol: 'J',
    alcedoTypeId: AlcedoTypes.ENERGY
  },
  [AlcedoUnit.KILOJOULES]: {
    id: AlcedoUnit.KILOJOULES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES'));
    },
    symbol: 'kJ',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1000
  },
  [AlcedoUnit.WATT_HOURS]: {
    id: AlcedoUnit.WATT_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATT_HOURS'));
    },
    symbol: 'Wh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600
  },
  [AlcedoUnit.KILOWATT_HOURS]: {
    id: AlcedoUnit.KILOWATT_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS'));
    },
    symbol: 'kWh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000
  },
  [AlcedoUnit.BTUS]: {
    id: AlcedoUnit.BTUS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BTUS'));
    },
    symbol: 'Btu (th)',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1055.05585
  },
  [AlcedoUnit.THERMS]: {
    id: AlcedoUnit.THERMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.THERMS'));
    },
    symbol: 'thm',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 105505585
  },
  [AlcedoUnit.TON_HOURS]: {
    id: AlcedoUnit.TON_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TON_HOURS'));
    },
    symbol: 'ton_hour',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 12660670.2314
  },
  [AlcedoUnit.JOULES_PER_KILOGRAM_DRY_AIR]: {
    id: AlcedoUnit.JOULES_PER_KILOGRAM_DRY_AIR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_KILOGRAM_DRY_AIR'));
    },
    symbol: 'J/kg dry air',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY
  },
  [AlcedoUnit.BTUS_PER_POUND_DRY_AIR]: {
    id: AlcedoUnit.BTUS_PER_POUND_DRY_AIR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BTUS_PER_POUND_DRY_AIR'));
    },
    symbol: 'Btu (th)/lb.',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 2325.9999942238887
  },
  [AlcedoUnit.CYCLES_PER_HOUR]: {
    id: AlcedoUnit.CYCLES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CYCLES_PER_HOUR'));
    },
    symbol: 'cycle/h',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.0002777777777777778
  },
  [AlcedoUnit.CYCLES_PER_MINUTE]: {
    id: AlcedoUnit.CYCLES_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CYCLES_PER_MINUTE'));
    },
    symbol: 'cycle/min',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.016666666666666666
  },
  [AlcedoUnit.HERTZ]: {
    id: AlcedoUnit.HERTZ,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HERTZ'));
    },
    symbol: 'Hz',
    alcedoTypeId: AlcedoTypes.FREQUENCY
  },
  [AlcedoUnit.GRAMS_OF_WATER_PER_KILOGRAM_DRY_AIR]: {
    id: AlcedoUnit.GRAMS_OF_WATER_PER_KILOGRAM_DRY_AIR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_OF_WATER_PER_KILOGRAM_DRY_AIR'));
    },
    symbol: 'g(H₂O)/kg dry air',
    alcedoTypeId: AlcedoTypes.ABSOLUTE_HUMIDITY
  },
  [AlcedoUnit.PERCENT_RELATIVE_HUMIDITY]: {
    id: AlcedoUnit.PERCENT_RELATIVE_HUMIDITY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PERCENT_RELATIVE_HUMIDITY'));
    },
    symbol: '% rh',
    alcedoTypeId: AlcedoTypes.RELATIVE_HUMIDITY
  },
  [AlcedoUnit.MILLIMETERS]: {
    id: AlcedoUnit.MILLIMETERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIMETERS'));
    },
    symbol: 'mm',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 0.001
  },
  [AlcedoUnit.METERS]: {
    id: AlcedoUnit.METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.METERS'));
    },
    symbol: 'm',
    alcedoTypeId: AlcedoTypes.DISTANCE
  },
  [AlcedoUnit.INCHES]: {
    id: AlcedoUnit.INCHES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.INCHES'));
    },
    symbol: 'in',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 0.0254
  },
  [AlcedoUnit.FEET]: {
    id: AlcedoUnit.FEET,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.FEET'));
    },
    symbol: 'ft',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 0.30479999999999996
  },
  [AlcedoUnit.WATTS_PER_SQUARE_FOOT]: {
    id: AlcedoUnit.WATTS_PER_SQUARE_FOOT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATTS_PER_SQUARE_FOOT'));
    },
    symbol: 'W/ft²',
    alcedoTypeId: AlcedoTypes.IRRADIANCE,
    multiplier: 10.763910416709725
  },
  [AlcedoUnit.WATTS_PER_SQUARE_METER]: {
    id: AlcedoUnit.WATTS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATTS_PER_SQUARE_METER'));
    },
    symbol: 'W/m²',
    alcedoTypeId: AlcedoTypes.IRRADIANCE
  },
  [AlcedoUnit.LUMENS]: {
    id: AlcedoUnit.LUMENS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LUMENS'));
    },
    symbol: 'lm',
    alcedoTypeId: AlcedoTypes.LUMINOUS_FLUX
  },
  [AlcedoUnit.LUXES]: {
    id: AlcedoUnit.LUXES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LUXES'));
    },
    symbol: 'lx',
    alcedoTypeId: AlcedoTypes.ILLUMINANCE
  },
  [AlcedoUnit.FOOT_CANDLES]: {
    id: AlcedoUnit.FOOT_CANDLES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.FOOT_CANDLES'));
    },
    symbol: 'fc',
    alcedoTypeId: AlcedoTypes.ILLUMINANCE,
    multiplier: 10.763910416709725
  },
  [AlcedoUnit.KILOGRAMS]: {
    id: AlcedoUnit.KILOGRAMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS'));
    },
    symbol: 'kg',
    alcedoTypeId: AlcedoTypes.MASS
  },
  [AlcedoUnit.POUNDS_MASS]: {
    id: AlcedoUnit.POUNDS_MASS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_MASS'));
    },
    symbol: 'lb.',
    alcedoTypeId: AlcedoTypes.MASS,
    multiplier: 0.45359237
  },
  [AlcedoUnit.TONS]: {
    id: AlcedoUnit.TONS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TONS'));
    },
    symbol: 't',
    alcedoTypeId: AlcedoTypes.MASS,
    multiplier: 1000
  },
  [AlcedoUnit.KILOGRAMS_PER_SECOND]: {
    id: AlcedoUnit.KILOGRAMS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_SECOND'));
    },
    symbol: 'kg/s',
    alcedoTypeId: AlcedoTypes.MASS_FLOW
  },
  [AlcedoUnit.KILOGRAMS_PER_MINUTE]: {
    id: AlcedoUnit.KILOGRAMS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_MINUTE'));
    },
    symbol: 'kg/min',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.016666666666666666
  },
  [AlcedoUnit.KILOGRAMS_PER_HOUR]: {
    id: AlcedoUnit.KILOGRAMS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_HOUR'));
    },
    symbol: 'kg/h',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.0002777777777777778
  },
  [AlcedoUnit.POUNDS_MASS_PER_MINUTE]: {
    id: AlcedoUnit.POUNDS_MASS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_MASS_PER_MINUTE'));
    },
    symbol: 'lb./min',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.007559872833333333
  },
  [AlcedoUnit.POUNDS_MASS_PER_HOUR]: {
    id: AlcedoUnit.POUNDS_MASS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_MASS_PER_HOUR'));
    },
    symbol: 'lb./h',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.00012599788055555556
  },
  [AlcedoUnit.WATTS]: {
    id: AlcedoUnit.WATTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATTS'));
    },
    symbol: 'W',
    alcedoTypeId: AlcedoTypes.POWER
  },
  [AlcedoUnit.KILOWATTS]: {
    id: AlcedoUnit.KILOWATTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATTS'));
    },
    symbol: 'kW',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAWATTS]: {
    id: AlcedoUnit.MEGAWATTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAWATTS'));
    },
    symbol: 'MW',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000000
  },
  [AlcedoUnit.BTUS_PER_HOUR]: {
    id: AlcedoUnit.BTUS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BTUS_PER_HOUR'));
    },
    symbol: 'Btu (th)/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 0.29307106944444444
  },
  [AlcedoUnit.HORSEPOWER]: {
    id: AlcedoUnit.HORSEPOWER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HORSEPOWER'));
    },
    symbol: 'hp',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 745.699872
  },
  [AlcedoUnit.TONS_REFRIGERATION]: {
    id: AlcedoUnit.TONS_REFRIGERATION,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TONS_REFRIGERATION'));
    },
    symbol: 'TR',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 3517
  },
  [AlcedoUnit.PASCALS]: {
    id: AlcedoUnit.PASCALS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PASCALS'));
    },
    symbol: 'Pa',
    alcedoTypeId: AlcedoTypes.PRESSURE
  },
  [AlcedoUnit.KILOPASCALS]: {
    id: AlcedoUnit.KILOPASCALS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOPASCALS'));
    },
    symbol: 'kPa',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 1000
  },
  [AlcedoUnit.BARS]: {
    id: AlcedoUnit.BARS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BARS'));
    },
    symbol: 'bar',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 100000
  },
  [AlcedoUnit.POUNDS_FORCE_PER_SQUARE_INCH]: {
    id: AlcedoUnit.POUNDS_FORCE_PER_SQUARE_INCH,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_FORCE_PER_SQUARE_INCH'));
    },
    symbol: 'psi',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 6894.757293168362
  },
  [AlcedoUnit.CENTIMETERS_OF_WATER]: {
    id: AlcedoUnit.CENTIMETERS_OF_WATER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CENTIMETERS_OF_WATER'));
    },
    symbol: 'cm H₂O',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 98.0665
  },
  [AlcedoUnit.INCHES_OF_WATER]: {
    id: AlcedoUnit.INCHES_OF_WATER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.INCHES_OF_WATER'));
    },
    symbol: 'in H₂O',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 249.08891000000003
  },
  [AlcedoUnit.MILLIMETERS_OF_MERCURY]: {
    id: AlcedoUnit.MILLIMETERS_OF_MERCURY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIMETERS_OF_MERCURY'));
    },
    symbol: 'mm Hg',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 133.32239
  },
  [AlcedoUnit.CENTIMETERS_OF_MERCURY]: {
    id: AlcedoUnit.CENTIMETERS_OF_MERCURY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CENTIMETERS_OF_MERCURY'));
    },
    symbol: 'cm Hg',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 1333.2239
  },
  [AlcedoUnit.INCHES_OF_MERCURY]: {
    id: AlcedoUnit.INCHES_OF_MERCURY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.INCHES_OF_MERCURY'));
    },
    symbol: 'in Hg',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 3386.3887059999997
  },
  [AlcedoUnit.DEGREES_CELSIUS]: {
    id: AlcedoUnit.DEGREES_CELSIUS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_CELSIUS'));
    },
    symbol: '°C',
    alcedoTypeId: AlcedoTypes.TEMPERATURE,
    multiplier: 1,
    offset: 273.15
  },
  [AlcedoUnit.KELVIN]: {
    id: AlcedoUnit.KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KELVIN'));
    },
    symbol: 'K',
    alcedoTypeId: AlcedoTypes.TEMPERATURE
  },
  [AlcedoUnit.DEGREES_FAHRENHEIT]: {
    id: AlcedoUnit.DEGREES_FAHRENHEIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_FAHRENHEIT'));
    },
    symbol: '°F',
    alcedoTypeId: AlcedoTypes.TEMPERATURE,
    multiplier: 0.5555555555555556,
    offset: 255.3722222222222
  },
  [AlcedoUnit.DEGREE_DAYS_CELSIUS]: {
    id: AlcedoUnit.DEGREE_DAYS_CELSIUS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREE_DAYS_CELSIUS'));
    },
    symbol: '°C d',
    alcedoTypeId: AlcedoTypes.TEMPERATURE_DEPARTURE
  },
  [AlcedoUnit.DEGREE_DAYS_FAHRENHEIT]: {
    id: AlcedoUnit.DEGREE_DAYS_FAHRENHEIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREE_DAYS_FAHRENHEIT'));
    },
    symbol: '°F d',
    alcedoTypeId: AlcedoTypes.TEMPERATURE_DEPARTURE,
    multiplier: 0.5555555555555556
  },
  [AlcedoUnit.YEARS]: {
    id: AlcedoUnit.YEARS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.YEARS'));
    },
    symbol: 'a',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 31536000
  },
  [AlcedoUnit.MONTHS]: {
    id: AlcedoUnit.MONTHS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MONTHS'));
    },
    symbol: 'mo',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 2592000
  },
  [AlcedoUnit.WEEKS]: {
    id: AlcedoUnit.WEEKS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WEEKS'));
    },
    symbol: 'wk',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 604800000
  },
  [AlcedoUnit.DAYS]: {
    id: AlcedoUnit.DAYS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DAYS'));
    },
    symbol: 'd',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 86400000
  },
  [AlcedoUnit.HOURS]: {
    id: AlcedoUnit.HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HOURS'));
    },
    symbol: 'h',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 3600000
  },
  [AlcedoUnit.MINUTES]: {
    id: AlcedoUnit.MINUTES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MINUTES'));
    },
    symbol: 'min',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 60000
  },
  [AlcedoUnit.SECONDS]: {
    id: AlcedoUnit.SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SECONDS'));
    },
    symbol: 's',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 1000
  },
  [AlcedoUnit.METERS_PER_SECOND]: {
    id: AlcedoUnit.METERS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.METERS_PER_SECOND'));
    },
    symbol: 'm/s',
    alcedoTypeId: AlcedoTypes.VELOCITY
  },
  [AlcedoUnit.KILOMETERS_PER_HOUR]: {
    id: AlcedoUnit.KILOMETERS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOMETERS_PER_HOUR'));
    },
    symbol: 'km/h',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.2777777777777778
  },
  [AlcedoUnit.FEET_PER_SECOND]: {
    id: AlcedoUnit.FEET_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.FEET_PER_SECOND'));
    },
    symbol: 'ft/s',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.30479999999999996
  },
  [AlcedoUnit.FEET_PER_MINUTE]: {
    id: AlcedoUnit.FEET_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.FEET_PER_MINUTE'));
    },
    symbol: 'ft/min',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.0050799999999999994
  },
  [AlcedoUnit.MILES_PER_HOUR]: {
    id: AlcedoUnit.MILES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILES_PER_HOUR'));
    },
    symbol: 'mph',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.44704
  },
  [AlcedoUnit.CUBIC_FEET]: {
    id: AlcedoUnit.CUBIC_FEET,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_FEET'));
    },
    symbol: 'ft³',
    alcedoTypeId: AlcedoTypes.VOLUME,
    multiplier: 0.02831684659199999
  },
  [AlcedoUnit.CUBIC_METERS]: {
    id: AlcedoUnit.CUBIC_METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_METERS'));
    },
    symbol: 'm³',
    alcedoTypeId: AlcedoTypes.VOLUME
  },
  [AlcedoUnit.IMPERIAL_GALLONS]: {
    id: AlcedoUnit.IMPERIAL_GALLONS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.IMPERIAL_GALLONS'));
    },
    symbol: 'Imp.gal.',
    alcedoTypeId: AlcedoTypes.VOLUME,
    multiplier: 0.00454609188
  },
  [AlcedoUnit.LITERS]: {
    id: AlcedoUnit.LITERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LITERS'));
    },
    symbol: 'l',
    alcedoTypeId: AlcedoTypes.VOLUME,
    multiplier: 0.001
  },
  [AlcedoUnit.US_GALLONS]: {
    id: AlcedoUnit.US_GALLONS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.US_GALLONS'));
    },
    symbol: 'US.liq.gal.',
    alcedoTypeId: AlcedoTypes.VOLUME,
    multiplier: 0.00378541178
  },
  [AlcedoUnit.CUBIC_FEET_PER_MINUTE]: {
    id: AlcedoUnit.CUBIC_FEET_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_FEET_PER_MINUTE'));
    },
    symbol: 'ft³/min',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 1.6990107955199993
  },
  [AlcedoUnit.CUBIC_METERS_PER_SECOND]: {
    id: AlcedoUnit.CUBIC_METERS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_METERS_PER_SECOND'));
    },
    symbol: 'm³/s',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 3600
  },
  [AlcedoUnit.IMPERIAL_GALLONS_PER_MINUTE]: {
    id: AlcedoUnit.IMPERIAL_GALLONS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.IMPERIAL_GALLONS_PER_MINUTE'));
    },
    symbol: 'Imp.gal./min',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.2727655128
  },
  [AlcedoUnit.LITERS_PER_SECOND]: {
    id: AlcedoUnit.LITERS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LITERS_PER_SECOND'));
    },
    symbol: 'l/s',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 3.6
  },
  [AlcedoUnit.LITERS_PER_MINUTE]: {
    id: AlcedoUnit.LITERS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LITERS_PER_MINUTE'));
    },
    symbol: 'l/min',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.060000000000000005
  },
  [AlcedoUnit.US_GALLONS_PER_MINUTE]: {
    id: AlcedoUnit.US_GALLONS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.US_GALLONS_PER_MINUTE'));
    },
    symbol: 'US.liq.gal.min',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.22712470680000002
  },
  [AlcedoUnit.DEGREES_ANGULAR]: {
    id: AlcedoUnit.DEGREES_ANGULAR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_ANGULAR'));
    },
    symbol: 'deg',
    alcedoTypeId: AlcedoTypes.ANGLE,
    multiplier: 0.017453292519943295
  },
  [AlcedoUnit.DEGREES_CELSIUS_PER_HOUR]: {
    id: AlcedoUnit.DEGREES_CELSIUS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_CELSIUS_PER_HOUR'));
    },
    symbol: '°C/h',
    alcedoTypeId: AlcedoTypes.COOLING_RATE
  },
  [AlcedoUnit.DEGREES_CELSIUS_PER_MINUTE]: {
    id: AlcedoUnit.DEGREES_CELSIUS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_CELSIUS_PER_MINUTE'));
    },
    symbol: '°C/min',
    alcedoTypeId: AlcedoTypes.COOLING_RATE,
    multiplier: 60
  },
  [AlcedoUnit.DEGREES_FAHRENHEIT_PER_HOUR]: {
    id: AlcedoUnit.DEGREES_FAHRENHEIT_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_FAHRENHEIT_PER_HOUR'));
    },
    symbol: '°F/h',
    alcedoTypeId: AlcedoTypes.COOLING_RATE,
    multiplier: 0.5555555555555556
  },
  [AlcedoUnit.DEGREES_FAHRENHEIT_PER_MINUTE]: {
    id: AlcedoUnit.DEGREES_FAHRENHEIT_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_FAHRENHEIT_PER_MINUTE'));
    },
    symbol: '°F/min',
    alcedoTypeId: AlcedoTypes.COOLING_RATE,
    multiplier: 33.333333333333336
  },
  [AlcedoUnit.NO_UNIT]: {
    id: AlcedoUnit.NO_UNIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NO_UNIT'));
    },
    symbol: '',
    alcedoTypeId: AlcedoTypes.NONE
  },
  [AlcedoUnit.PARTS_PER_MILLION]: {
    id: AlcedoUnit.PARTS_PER_MILLION,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PARTS_PER_MILLION'));
    },
    symbol: 'ppm',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 0.0001
  },
  [AlcedoUnit.PARTS_PER_BILLION]: {
    id: AlcedoUnit.PARTS_PER_BILLION,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PARTS_PER_BILLION'));
    },
    symbol: 'ppb',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 1e-7
  },
  [AlcedoUnit.PERCENT]: {
    id: AlcedoUnit.PERCENT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PERCENT'));
    },
    symbol: '%',
    alcedoTypeId: AlcedoTypes.RATIO
  },
  [AlcedoUnit.PERCENT_PER_SECOND]: {
    id: AlcedoUnit.PERCENT_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PERCENT_PER_SECOND'));
    },
    symbol: '%/s',
    alcedoTypeId: AlcedoTypes.FREQUENCY
  },
  [AlcedoUnit.PER_MINUTE]: {
    id: AlcedoUnit.PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PER_MINUTE'));
    },
    symbol: '/min',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.016666666666666666
  },
  [AlcedoUnit.PER_SECOND]: {
    id: AlcedoUnit.PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PER_SECOND'));
    },
    symbol: '/s',
    alcedoTypeId: AlcedoTypes.FREQUENCY
  },
  [AlcedoUnit.PSI_PER_DEGREE_FAHRENHEIT]: {
    id: AlcedoUnit.PSI_PER_DEGREE_FAHRENHEIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PSI_PER_DEGREE_FAHRENHEIT'));
    },
    symbol: 'psi/°F',
    alcedoTypeId: AlcedoTypes.PRESSURE
  },
  [AlcedoUnit.RADIANS]: {
    id: AlcedoUnit.RADIANS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.RADIANS'));
    },
    symbol: 'rad',
    alcedoTypeId: AlcedoTypes.ANGLE
  },
  [AlcedoUnit.REVOLUTIONS_PER_MINUTE]: {
    id: AlcedoUnit.REVOLUTIONS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.REVOLUTIONS_PER_MINUTE'));
    },
    symbol: 'rpm',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.016666666666666666
  },
  [AlcedoUnit.CURRENCY1]: {
    id: AlcedoUnit.CURRENCY1,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY1'));
    },
    symbol: 'currency1',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY2]: {
    id: AlcedoUnit.CURRENCY2,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY2'));
    },
    symbol: 'currency2',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY3]: {
    id: AlcedoUnit.CURRENCY3,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY3'));
    },
    symbol: 'currency3',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY4]: {
    id: AlcedoUnit.CURRENCY4,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY4'));
    },
    symbol: 'currency4',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY5]: {
    id: AlcedoUnit.CURRENCY5,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY5'));
    },
    symbol: 'currency5',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY6]: {
    id: AlcedoUnit.CURRENCY6,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY6'));
    },
    symbol: 'currency6',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY7]: {
    id: AlcedoUnit.CURRENCY7,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY7'));
    },
    symbol: 'currency7',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY8]: {
    id: AlcedoUnit.CURRENCY8,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY8'));
    },
    symbol: 'currency8',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY9]: {
    id: AlcedoUnit.CURRENCY9,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY9'));
    },
    symbol: 'currency9',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.CURRENCY10]: {
    id: AlcedoUnit.CURRENCY10,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CURRENCY10'));
    },
    symbol: 'currency10',
    alcedoTypeId: AlcedoTypes.CURRENCY
  },
  [AlcedoUnit.SQUARE_INCHES]: {
    id: AlcedoUnit.SQUARE_INCHES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SQUARE_INCHES'));
    },
    symbol: 'in²',
    alcedoTypeId: AlcedoTypes.AREA,
    multiplier: 0.00064516
  },
  [AlcedoUnit.SQUARE_CENTIMETERS]: {
    id: AlcedoUnit.SQUARE_CENTIMETERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SQUARE_CENTIMETERS'));
    },
    symbol: 'cm²',
    alcedoTypeId: AlcedoTypes.AREA,
    multiplier: 0.0001
  },
  [AlcedoUnit.BTUS_PER_POUND]: {
    id: AlcedoUnit.BTUS_PER_POUND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BTUS_PER_POUND'));
    },
    symbol: 'Btu (th)/lb.',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 2325.9999942238887
  },
  [AlcedoUnit.CENTIMETERS]: {
    id: AlcedoUnit.CENTIMETERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CENTIMETERS'));
    },
    symbol: 'cm',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 0.01
  },
  [AlcedoUnit.POUNDS_MASS_PER_SECOND]: {
    id: AlcedoUnit.POUNDS_MASS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_MASS_PER_SECOND'));
    },
    symbol: 'lb./s',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.45359237
  },
  [AlcedoUnit.DELTA_DEGREES_FAHRENHEIT]: {
    id: AlcedoUnit.DELTA_DEGREES_FAHRENHEIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DELTA_DEGREES_FAHRENHEIT'));
    },
    symbol: '°F',
    alcedoTypeId: AlcedoTypes.TEMPERATURE_DIFFERENCE,
    multiplier: 0.5555555555555556
  },
  [AlcedoUnit.DELTA_KELVIN]: {
    id: AlcedoUnit.DELTA_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DELTA_KELVIN'));
    },
    symbol: 'K',
    alcedoTypeId: AlcedoTypes.TEMPERATURE_DIFFERENCE
  },
  [AlcedoUnit.KILOHMS]: {
    id: AlcedoUnit.KILOHMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOHMS'));
    },
    symbol: 'kΩ',
    alcedoTypeId: AlcedoTypes.RESISTANCE,
    multiplier: 1000
  },
  [AlcedoUnit.MEGOHMS]: {
    id: AlcedoUnit.MEGOHMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGOHMS'));
    },
    symbol: 'MΩ',
    alcedoTypeId: AlcedoTypes.RESISTANCE,
    multiplier: 1000000
  },
  [AlcedoUnit.MILLIVOLTS]: {
    id: AlcedoUnit.MILLIVOLTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIVOLTS'));
    },
    symbol: 'mV',
    alcedoTypeId: AlcedoTypes.VOLTAGE,
    multiplier: 0.001
  },
  [AlcedoUnit.KILOJOULES_PER_KILOGRAM]: {
    id: AlcedoUnit.KILOJOULES_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES_PER_KILOGRAM'));
    },
    symbol: 'kJ/kg',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAJOULES]: {
    id: AlcedoUnit.MEGAJOULES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES'));
    },
    symbol: 'MJ',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1000000
  },
  [AlcedoUnit.JOULES_PER_KELVIN]: {
    id: AlcedoUnit.JOULES_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_KELVIN'));
    },
    symbol: 'J/K',
    alcedoTypeId: AlcedoTypes.ENTROPY
  },
  [AlcedoUnit.JOULES_PER_KILOGRAM_KELVIN]: {
    id: AlcedoUnit.JOULES_PER_KILOGRAM_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_KILOGRAM_KELVIN'));
    },
    symbol: 'J/kg·K',
    alcedoTypeId: AlcedoTypes.SPECIFIC_HEAT
  },
  [AlcedoUnit.KILOHERTZ]: {
    id: AlcedoUnit.KILOHERTZ,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOHERTZ'));
    },
    symbol: 'kHz',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAHERTZ]: {
    id: AlcedoUnit.MEGAHERTZ,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAHERTZ'));
    },
    symbol: 'MHz',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 1000000
  },
  [AlcedoUnit.PER_HOUR]: {
    id: AlcedoUnit.PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PER_HOUR'));
    },
    symbol: '/h',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.0002777777777777778
  },
  [AlcedoUnit.MILLIWATTS]: {
    id: AlcedoUnit.MILLIWATTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIWATTS'));
    },
    symbol: 'mW',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 0.001
  },
  [AlcedoUnit.HECTOPASCALS]: {
    id: AlcedoUnit.HECTOPASCALS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HECTOPASCALS'));
    },
    symbol: 'hPa',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 100
  },
  [AlcedoUnit.MILLIBARS]: {
    id: AlcedoUnit.MILLIBARS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIBARS'));
    },
    symbol: 'mbar',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 100
  },
  [AlcedoUnit.CUBIC_METERS_PER_HOUR]: {
    id: AlcedoUnit.CUBIC_METERS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_METERS_PER_HOUR'));
    },
    symbol: 'm³/h',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.LITERS_PER_HOUR]: {
    id: AlcedoUnit.LITERS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.LITERS_PER_HOUR'));
    },
    symbol: 'l/h',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.001
  },
  [AlcedoUnit.KILOWATT_HOURS_PER_SQUARE_METER]: {
    id: AlcedoUnit.KILOWATT_HOURS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS_PER_SQUARE_METER'));
    },
    symbol: 'kWh/m²',
    alcedoTypeId: AlcedoTypes.INSOLATION,
    multiplier: 1000
  },
  [AlcedoUnit.KILOWATT_HOURS_PER_SQUARE_FOOT]: {
    id: AlcedoUnit.KILOWATT_HOURS_PER_SQUARE_FOOT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS_PER_SQUARE_FOOT'));
    },
    symbol: 'kWh/ft²',
    alcedoTypeId: AlcedoTypes.INSOLATION,
    multiplier: 10763.910416709725
  },
  [AlcedoUnit.MEGAJOULES_PER_SQUARE_METER]: {
    id: AlcedoUnit.MEGAJOULES_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_SQUARE_METER'));
    },
    symbol: 'MJ/m²',
    alcedoTypeId: AlcedoTypes.INSOLATION,
    multiplier: 277.77777777777777
  },
  [AlcedoUnit.MEGAJOULES_PER_SQUARE_FOOT]: {
    id: AlcedoUnit.MEGAJOULES_PER_SQUARE_FOOT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_SQUARE_FOOT'));
    },
    symbol: 'MJ/ft²',
    alcedoTypeId: AlcedoTypes.INSOLATION,
    multiplier: 2989.975115752701
  },
  [AlcedoUnit.WATTS_PER_SQUARE_METER_KELVIN]: {
    id: AlcedoUnit.WATTS_PER_SQUARE_METER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATTS_PER_SQUARE_METER_KELVIN'));
    },
    symbol: 'W/m²·K',
    alcedoTypeId: AlcedoTypes.THERMAL_TRANSMITTANCE
  },
  [AlcedoUnit.CUBIC_FEET_PER_SECOND]: {
    id: AlcedoUnit.CUBIC_FEET_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_FEET_PER_SECOND'));
    },
    symbol: 'ft³/s',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 101.94064773119996
  },
  [AlcedoUnit.PERCENT_OBSCURATION_PER_FOOT]: {
    id: AlcedoUnit.PERCENT_OBSCURATION_PER_FOOT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PERCENT_OBSCURATION_PER_FOOT'));
    },
    symbol: '% obs./ft.',
    alcedoTypeId: AlcedoTypes.RATIO
  },
  [AlcedoUnit.PERCENT_OBSCURATION_PER_METER]: {
    id: AlcedoUnit.PERCENT_OBSCURATION_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PERCENT_OBSCURATION_PER_METER'));
    },
    symbol: '% obs./mtr.',
    alcedoTypeId: AlcedoTypes.RATIO
  },
  [AlcedoUnit.MILLIOHMS]: {
    id: AlcedoUnit.MILLIOHMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIOHMS'));
    },
    symbol: 'mΩ',
    alcedoTypeId: AlcedoTypes.RESISTANCE,
    multiplier: 0.001
  },
  [AlcedoUnit.MEGAWATT_HOURS]: {
    id: AlcedoUnit.MEGAWATT_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAWATT_HOURS'));
    },
    symbol: 'MWh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000000
  },
  [AlcedoUnit.KILO_BTUS]: {
    id: AlcedoUnit.KILO_BTUS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILO_BTUS'));
    },
    symbol: 'kBtu (th)',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1055055.8499999999
  },
  [AlcedoUnit.MEGA_BTUS]: {
    id: AlcedoUnit.MEGA_BTUS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGA_BTUS'));
    },
    symbol: 'MBtu (th)',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1055055850
  },
  [AlcedoUnit.KILOJOULES_PER_KILOGRAM_DRY_AIR]: {
    id: AlcedoUnit.KILOJOULES_PER_KILOGRAM_DRY_AIR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES_PER_KILOGRAM_DRY_AIR'));
    },
    symbol: 'kJ/kg dry air',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAJOULES_PER_KILOGRAM_DRY_AIR]: {
    id: AlcedoUnit.MEGAJOULES_PER_KILOGRAM_DRY_AIR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_KILOGRAM_DRY_AIR'));
    },
    symbol: 'MJ/kg dry air',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 1000000
  },
  [AlcedoUnit.KILOJOULES_PER_KELVIN]: {
    id: AlcedoUnit.KILOJOULES_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES_PER_KELVIN'));
    },
    symbol: 'kJ/K',
    alcedoTypeId: AlcedoTypes.ENTROPY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAJOULES_PER_KELVIN]: {
    id: AlcedoUnit.MEGAJOULES_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_KELVIN'));
    },
    symbol: 'MJ/K',
    alcedoTypeId: AlcedoTypes.ENTROPY,
    multiplier: 1000000
  },
  [AlcedoUnit.NEWTON]: {
    id: AlcedoUnit.NEWTON,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NEWTON'));
    },
    symbol: 'N',
    alcedoTypeId: AlcedoTypes.FORCE
  },
  [AlcedoUnit.GRAMS_PER_SECOND]: {
    id: AlcedoUnit.GRAMS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_SECOND'));
    },
    symbol: 'g/s',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.001
  },
  [AlcedoUnit.GRAMS_PER_MINUTE]: {
    id: AlcedoUnit.GRAMS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_MINUTE'));
    },
    symbol: 'g/min',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.000016666666666666667
  },
  [AlcedoUnit.TONS_PER_HOUR]: {
    id: AlcedoUnit.TONS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TONS_PER_HOUR'));
    },
    symbol: 't/h',
    alcedoTypeId: AlcedoTypes.MASS_FLOW,
    multiplier: 0.2777777777777778
  },
  [AlcedoUnit.KILO_BTUS_PER_HOUR]: {
    id: AlcedoUnit.KILO_BTUS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILO_BTUS_PER_HOUR'));
    },
    symbol: 'kBtu (th)/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 293.0710694444444
  },
  [AlcedoUnit.HUNDREDTHS_SECONDS]: {
    id: AlcedoUnit.HUNDREDTHS_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HUNDREDTHS_SECONDS'));
    },
    symbol: '/100 s',
    alcedoTypeId: AlcedoTypes.TIMESPAN,
    multiplier: 10
  },
  [AlcedoUnit.MILLISECONDS]: {
    id: AlcedoUnit.MILLISECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLISECONDS'));
    },
    symbol: 'ms',
    alcedoTypeId: AlcedoTypes.TIMESPAN
  },
  [AlcedoUnit.NEWTON_METERS]: {
    id: AlcedoUnit.NEWTON_METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NEWTON_METERS'));
    },
    symbol: 'Nm',
    alcedoTypeId: AlcedoTypes.ENERGY
  },
  [AlcedoUnit.MILLIMETERS_PER_SECOND]: {
    id: AlcedoUnit.MILLIMETERS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIMETERS_PER_SECOND'));
    },
    symbol: 'mm/s',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.001
  },
  [AlcedoUnit.MILLIMETERS_PER_MINUTE]: {
    id: AlcedoUnit.MILLIMETERS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIMETERS_PER_MINUTE'));
    },
    symbol: 'mm/min',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.000016666666666666667
  },
  [AlcedoUnit.METERS_PER_MINUTE]: {
    id: AlcedoUnit.METERS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.METERS_PER_MINUTE'));
    },
    symbol: 'm/min',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.016666666666666666
  },
  [AlcedoUnit.METERS_PER_HOUR]: {
    id: AlcedoUnit.METERS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.METERS_PER_HOUR'));
    },
    symbol: 'm/h',
    alcedoTypeId: AlcedoTypes.VELOCITY,
    multiplier: 0.0002777777777777778
  },
  [AlcedoUnit.CUBIC_METERS_PER_MINUTE]: {
    id: AlcedoUnit.CUBIC_METERS_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_METERS_PER_MINUTE'));
    },
    symbol: 'm³/min',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 60
  },
  [AlcedoUnit.METERS_PER_SECOND_PER_SECOND]: {
    id: AlcedoUnit.METERS_PER_SECOND_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.METERS_PER_SECOND_PER_SECOND'));
    },
    symbol: 'm/s²',
    alcedoTypeId: AlcedoTypes.ACCELERATION
  },
  [AlcedoUnit.AMPERES_PER_METER]: {
    id: AlcedoUnit.AMPERES_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERES_PER_METER'));
    },
    symbol: 'A/m',
    alcedoTypeId: AlcedoTypes.MAGNETIC_FIELD_STRENGTH
  },
  [AlcedoUnit.AMPERES_PER_SQUARE_METER]: {
    id: AlcedoUnit.AMPERES_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERES_PER_SQUARE_METER'));
    },
    symbol: 'A/m²',
    alcedoTypeId: AlcedoTypes.CURRENT_DENSITY
  },
  [AlcedoUnit.AMPERE_SQUARE_METERS]: {
    id: AlcedoUnit.AMPERE_SQUARE_METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERE_SQUARE_METERS'));
    },
    symbol: 'Am²',
    alcedoTypeId: AlcedoTypes.MAGNETIC_MOMENT
  },
  [AlcedoUnit.FARADS]: {
    id: AlcedoUnit.FARADS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.FARADS'));
    },
    symbol: 'F',
    alcedoTypeId: AlcedoTypes.CAPACITANCE
  },
  [AlcedoUnit.HENRYS]: {
    id: AlcedoUnit.HENRYS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.HENRYS'));
    },
    symbol: 'H',
    alcedoTypeId: AlcedoTypes.INDUCTANCE
  },
  [AlcedoUnit.OHM_METERS]: {
    id: AlcedoUnit.OHM_METERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.OHM_METERS'));
    },
    symbol: 'Ωm',
    alcedoTypeId: AlcedoTypes.RESISTIVITY
  },
  [AlcedoUnit.SIEMENS]: {
    id: AlcedoUnit.SIEMENS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SIEMENS'));
    },
    symbol: 'S',
    alcedoTypeId: AlcedoTypes.CONDUCTANCE
  },
  [AlcedoUnit.SIEMENS_PER_METER]: {
    id: AlcedoUnit.SIEMENS_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SIEMENS_PER_METER'));
    },
    symbol: 'S/m',
    alcedoTypeId: AlcedoTypes.CONDUCTIVITY
  },
  [AlcedoUnit.TESLAS]: {
    id: AlcedoUnit.TESLAS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TESLAS'));
    },
    symbol: 'T',
    alcedoTypeId: AlcedoTypes.MAGNETIC_FLUX_DENSITY
  },
  [AlcedoUnit.VOLTS_PER_KELVIN]: {
    id: AlcedoUnit.VOLTS_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLTS_PER_KELVIN'));
    },
    symbol: 'V/K',
    alcedoTypeId: AlcedoTypes.THOMSON_COEFFICIENT
  },
  [AlcedoUnit.VOLTS_PER_METER]: {
    id: AlcedoUnit.VOLTS_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLTS_PER_METER'));
    },
    symbol: 'V/m',
    alcedoTypeId: AlcedoTypes.ELECTRIC_FIELD_STRENGTH
  },
  [AlcedoUnit.WEBERS]: {
    id: AlcedoUnit.WEBERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WEBERS'));
    },
    symbol: 'Wb',
    alcedoTypeId: AlcedoTypes.MAGNETIC_FLUX
  },
  [AlcedoUnit.CANDELAS]: {
    id: AlcedoUnit.CANDELAS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CANDELAS'));
    },
    symbol: 'cd',
    alcedoTypeId: AlcedoTypes.LUMINOUS_INTENSITY
  },
  [AlcedoUnit.CANDELAS_PER_SQUARE_METER]: {
    id: AlcedoUnit.CANDELAS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CANDELAS_PER_SQUARE_METER'));
    },
    symbol: 'cd/m²',
    alcedoTypeId: AlcedoTypes.LUMINANCE
  },
  [AlcedoUnit.KELVIN_PER_HOUR]: {
    id: AlcedoUnit.KELVIN_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KELVIN_PER_HOUR'));
    },
    symbol: 'K/h',
    alcedoTypeId: AlcedoTypes.COOLING_RATE
  },
  [AlcedoUnit.KELVIN_PER_MINUTE]: {
    id: AlcedoUnit.KELVIN_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KELVIN_PER_MINUTE'));
    },
    symbol: 'K/min',
    alcedoTypeId: AlcedoTypes.COOLING_RATE,
    multiplier: 60
  },
  [AlcedoUnit.JOULE_SECONDS]: {
    id: AlcedoUnit.JOULE_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULE_SECONDS'));
    },
    symbol: 'Js',
    alcedoTypeId: AlcedoTypes.ANGULAR_MOMENTUM
  },
  [AlcedoUnit.RADIANS_PER_SECOND]: {
    id: AlcedoUnit.RADIANS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.RADIANS_PER_SECOND'));
    },
    symbol: 'rad/s',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.15915494309189535
  },
  [AlcedoUnit.SQUARE_METERS_PER_NEWTON]: {
    id: AlcedoUnit.SQUARE_METERS_PER_NEWTON,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SQUARE_METERS_PER_NEWTON'));
    },
    symbol: 'm²/N',
    alcedoTypeId: AlcedoTypes.COMPRESSIBILITY
  },
  [AlcedoUnit.KILOGRAMS_PER_CUBIC_METER]: {
    id: AlcedoUnit.KILOGRAMS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_CUBIC_METER'));
    },
    symbol: 'kg/m³',
    alcedoTypeId: AlcedoTypes.DENSITY
  },
  [AlcedoUnit.NEWTON_SECONDS]: {
    id: AlcedoUnit.NEWTON_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NEWTON_SECONDS'));
    },
    symbol: 'Ns',
    alcedoTypeId: AlcedoTypes.MOMENTUM
  },
  [AlcedoUnit.NEWTONS_PER_METER]: {
    id: AlcedoUnit.NEWTONS_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NEWTONS_PER_METER'));
    },
    symbol: 'N/m',
    alcedoTypeId: AlcedoTypes.SURFACE_TENSION
  },
  [AlcedoUnit.WATTS_PER_METER_PER_KELVIN]: {
    id: AlcedoUnit.WATTS_PER_METER_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATTS_PER_METER_PER_KELVIN'));
    },
    symbol: 'W/m·K',
    alcedoTypeId: AlcedoTypes.THERMAL_CONDUCTIVITY
  },
  [AlcedoUnit.MICROSIEMENS]: {
    id: AlcedoUnit.MICROSIEMENS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROSIEMENS'));
    },
    symbol: 'μS',
    alcedoTypeId: AlcedoTypes.CONDUCTANCE,
    multiplier: 0.000001
  },
  [AlcedoUnit.CUBIC_FEET_PER_HOUR]: {
    id: AlcedoUnit.CUBIC_FEET_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_FEET_PER_HOUR'));
    },
    symbol: 'ft3/hour',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.02831684659199999
  },
  [AlcedoUnit.US_GALLONS_PER_HOUR]: {
    id: AlcedoUnit.US_GALLONS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.US_GALLONS_PER_HOUR'));
    },
    symbol: 'gph',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.00378541178
  },
  [AlcedoUnit.KILOMETERS]: {
    id: AlcedoUnit.KILOMETERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOMETERS'));
    },
    symbol: 'km',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 1000
  },
  [AlcedoUnit.MICROMETERS]: {
    id: AlcedoUnit.MICROMETERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROMETERS'));
    },
    symbol: 'µm',
    alcedoTypeId: AlcedoTypes.DISTANCE,
    multiplier: 0.000001
  },
  [AlcedoUnit.GRAMS]: {
    id: AlcedoUnit.GRAMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS'));
    },
    symbol: 'g',
    alcedoTypeId: AlcedoTypes.MASS,
    multiplier: 0.001
  },
  [AlcedoUnit.MILLIGRAMS]: {
    id: AlcedoUnit.MILLIGRAMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAMS'));
    },
    symbol: 'mg',
    alcedoTypeId: AlcedoTypes.MASS,
    multiplier: 0.000001
  },
  [AlcedoUnit.MILLILITERS]: {
    id: AlcedoUnit.MILLILITERS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLILITERS'));
    },
    symbol: 'ml',
    alcedoTypeId: AlcedoTypes.VOLUME,
    multiplier: 0.000001
  },
  [AlcedoUnit.MILLILITERS_PER_SECOND]: {
    id: AlcedoUnit.MILLILITERS_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLILITERS_PER_SECOND'));
    },
    symbol: 'ml/s',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.0036
  },
  [AlcedoUnit.DECIBELS]: {
    id: AlcedoUnit.DECIBELS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS'));
    },
    symbol: 'dB',
    alcedoTypeId: AlcedoTypes.DECIBEL
  },
  [AlcedoUnit.DECIBELS_MILLIVOLT]: {
    id: AlcedoUnit.DECIBELS_MILLIVOLT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS_MILLIVOLT'));
    },
    symbol: 'dBmV',
    alcedoTypeId: AlcedoTypes.VOLTAGE_LEVEL
  },
  [AlcedoUnit.DECIBELS_VOLT]: {
    id: AlcedoUnit.DECIBELS_VOLT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS_VOLT'));
    },
    symbol: 'dbV',
    alcedoTypeId: AlcedoTypes.VOLTAGE_LEVEL,
    multiplier: 1,
    offset: 30
  },
  [AlcedoUnit.MILLISIEMENS]: {
    id: AlcedoUnit.MILLISIEMENS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLISIEMENS'));
    },
    symbol: 'mS',
    alcedoTypeId: AlcedoTypes.CONDUCTANCE,
    multiplier: 0.001
  },
  [AlcedoUnit.WATT_HOURS_REACTIVE]: {
    id: AlcedoUnit.WATT_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATT_HOURS_REACTIVE'));
    },
    symbol: 'varh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600
  },
  [AlcedoUnit.KILOWATT_HOURS_REACTIVE]: {
    id: AlcedoUnit.KILOWATT_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS_REACTIVE'));
    },
    symbol: 'kvarh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000
  },
  [AlcedoUnit.MEGAWATT_HOURS_REACTIVE]: {
    id: AlcedoUnit.MEGAWATT_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAWATT_HOURS_REACTIVE'));
    },
    symbol: 'Mvarh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000000
  },
  [AlcedoUnit.MILLIMETERS_OF_WATER]: {
    id: AlcedoUnit.MILLIMETERS_OF_WATER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIMETERS_OF_WATER'));
    },
    symbol: 'mm H₂O',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 9.806650000000001
  },
  [AlcedoUnit.PER_MILLE]: {
    id: AlcedoUnit.PER_MILLE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PER_MILLE'));
    },
    symbol: '‰',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 0.1
  },
  [AlcedoUnit.GRAMS_PER_GRAM]: {
    id: AlcedoUnit.GRAMS_PER_GRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_GRAM'));
    },
    symbol: 'g/g',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 100
  },
  [AlcedoUnit.KILOGRAMS_PER_KILOGRAM]: {
    id: AlcedoUnit.KILOGRAMS_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_KILOGRAM'));
    },
    symbol: 'kg/kg',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 100
  },
  [AlcedoUnit.GRAMS_PER_KILOGRAM]: {
    id: AlcedoUnit.GRAMS_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_KILOGRAM'));
    },
    symbol: 'g/kg',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 0.1
  },
  [AlcedoUnit.MILLIGRAMS_PER_GRAM]: {
    id: AlcedoUnit.MILLIGRAMS_PER_GRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAMS_PER_GRAM'));
    },
    symbol: 'mg/g',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 0.1
  },
  [AlcedoUnit.MILLIGRAMS_PER_KILOGRAM]: {
    id: AlcedoUnit.MILLIGRAMS_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAMS_PER_KILOGRAM'));
    },
    symbol: 'mg/kg',
    alcedoTypeId: AlcedoTypes.RATIO,
    multiplier: 0.00009999999999999999
  },
  [AlcedoUnit.GRAMS_PER_MILLILITER]: {
    id: AlcedoUnit.GRAMS_PER_MILLILITER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_MILLILITER'));
    },
    symbol: 'g/ml',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 1000
  },
  [AlcedoUnit.GRAMS_PER_LITER]: {
    id: AlcedoUnit.GRAMS_PER_LITER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_LITER'));
    },
    symbol: 'g/l',
    alcedoTypeId: AlcedoTypes.DENSITY
  },
  [AlcedoUnit.MILLIGRAMS_PER_LITER]: {
    id: AlcedoUnit.MILLIGRAMS_PER_LITER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAMS_PER_LITER'));
    },
    symbol: 'mg/l',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 0.001
  },
  [AlcedoUnit.MICROGRAMS_PER_LITER]: {
    id: AlcedoUnit.MICROGRAMS_PER_LITER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROGRAMS_PER_LITER'));
    },
    symbol: 'µg/l',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 0.000001
  },
  [AlcedoUnit.GRAMS_PER_CUBIC_METER]: {
    id: AlcedoUnit.GRAMS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_CUBIC_METER'));
    },
    symbol: 'g/m³',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 0.001
  },
  [AlcedoUnit.MILLIGRAMS_PER_CUBIC_METER]: {
    id: AlcedoUnit.MILLIGRAMS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAMS_PER_CUBIC_METER'));
    },
    symbol: 'mg/m³',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 0.000001
  },
  [AlcedoUnit.MICROGRAMS_PER_CUBIC_METER]: {
    id: AlcedoUnit.MICROGRAMS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROGRAMS_PER_CUBIC_METER'));
    },
    symbol: 'µg/m³',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 1e-9
  },
  [AlcedoUnit.NANOGRAMS_PER_CUBIC_METER]: {
    id: AlcedoUnit.NANOGRAMS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NANOGRAMS_PER_CUBIC_METER'));
    },
    symbol: 'ng/m³',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 1e-12
  },
  [AlcedoUnit.GRAMS_PER_CUBIC_CENTIMETER]: {
    id: AlcedoUnit.GRAMS_PER_CUBIC_CENTIMETER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_CUBIC_CENTIMETER'));
    },
    symbol: 'g/cm³',
    alcedoTypeId: AlcedoTypes.DENSITY,
    multiplier: 0.001
  },
  [AlcedoUnit.BECQUERELS]: {
    id: AlcedoUnit.BECQUERELS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BECQUERELS'));
    },
    symbol: 'Bq',
    alcedoTypeId: AlcedoTypes.RADIATION_ACTIVITY
  },
  [AlcedoUnit.KILOBECQUERELS]: {
    id: AlcedoUnit.KILOBECQUERELS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOBECQUERELS'));
    },
    symbol: 'kBq',
    alcedoTypeId: AlcedoTypes.RADIATION_ACTIVITY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGABECQUERELS]: {
    id: AlcedoUnit.MEGABECQUERELS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGABECQUERELS'));
    },
    symbol: 'MBq',
    alcedoTypeId: AlcedoTypes.RADIATION_ACTIVITY,
    multiplier: 1000000
  },
  [AlcedoUnit.GRAY]: {
    id: AlcedoUnit.GRAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAY'));
    },
    symbol: 'Gy',
    alcedoTypeId: AlcedoTypes.RADIATION_ABSORBED_DOSE
  },
  [AlcedoUnit.MILLIGRAY]: {
    id: AlcedoUnit.MILLIGRAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIGRAY'));
    },
    symbol: 'mGy',
    alcedoTypeId: AlcedoTypes.RADIATION_ABSORBED_DOSE,
    multiplier: 0.001
  },
  [AlcedoUnit.MICROGRAY]: {
    id: AlcedoUnit.MICROGRAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROGRAY'));
    },
    symbol: 'µGy',
    alcedoTypeId: AlcedoTypes.RADIATION_ABSORBED_DOSE,
    multiplier: 0.000001
  },
  [AlcedoUnit.SIEVERTS]: {
    id: AlcedoUnit.SIEVERTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SIEVERTS'));
    },
    symbol: 'Sv',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE
  },
  [AlcedoUnit.MILLISIEVERTS]: {
    id: AlcedoUnit.MILLISIEVERTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLISIEVERTS'));
    },
    symbol: 'mSv',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE,
    multiplier: 0.001
  },
  [AlcedoUnit.MICROSIEVERTS]: {
    id: AlcedoUnit.MICROSIEVERTS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROSIEVERTS'));
    },
    symbol: 'µSv',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE,
    multiplier: 0.000001
  },
  [AlcedoUnit.MICROSIEVERTS_PER_HOUR]: {
    id: AlcedoUnit.MICROSIEVERTS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROSIEVERTS_PER_HOUR'));
    },
    symbol: 'µSv/h',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE_RATE,
    multiplier: 0.000001
  },
  [AlcedoUnit.DECIBELS_A]: {
    id: AlcedoUnit.DECIBELS_A,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS_A'));
    },
    symbol: 'dBA',
    alcedoTypeId: AlcedoTypes.A_WEIGHTING
  },
  [AlcedoUnit.NEPHELOMETRIC_TURBIDITY_UNIT]: {
    id: AlcedoUnit.NEPHELOMETRIC_TURBIDITY_UNIT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.NEPHELOMETRIC_TURBIDITY_UNIT'));
    },
    symbol: 'NTU',
    alcedoTypeId: AlcedoTypes.TURBIDITY
  },
  [AlcedoUnit.PH]: {
    id: AlcedoUnit.PH,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PH'));
    },
    symbol: 'pH',
    alcedoTypeId: AlcedoTypes.ACIDITY
  },
  [AlcedoUnit.GRAMS_PER_SQUARE_METER]: {
    id: AlcedoUnit.GRAMS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GRAMS_PER_SQUARE_METER'));
    },
    symbol: 'g/m²',
    alcedoTypeId: AlcedoTypes.AREA_DENSITY
  },
  [AlcedoUnit.MINUTES_PER_KELVIN]: {
    id: AlcedoUnit.MINUTES_PER_KELVIN,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MINUTES_PER_KELVIN'));
    },
    symbol: 'min/K',
    alcedoTypeId: AlcedoTypes.HEATING_RATE
  },
  [AlcedoUnit.OHM_METER_SQUARED_PER_METER]: {
    id: AlcedoUnit.OHM_METER_SQUARED_PER_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.OHM_METER_SQUARED_PER_METER'));
    },
    symbol: 'Ωm²/m',
    alcedoTypeId: AlcedoTypes.RESISTIVITY
  },
  [AlcedoUnit.AMPERE_SECONDS]: {
    id: AlcedoUnit.AMPERE_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERE_SECONDS'));
    },
    symbol: 'As',
    alcedoTypeId: AlcedoTypes.ELECTRIC_CHARGE
  },
  [AlcedoUnit.VOLT_AMPERE_HOURS]: {
    id: AlcedoUnit.VOLT_AMPERE_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLT_AMPERE_HOURS'));
    },
    symbol: 'VAh',
    alcedoTypeId: AlcedoTypes.ENERGY
  },
  [AlcedoUnit.KILOVOLT_AMPERE_HOURS]: {
    id: AlcedoUnit.KILOVOLT_AMPERE_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOVOLT_AMPERE_HOURS'));
    },
    symbol: 'kVAh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAVOLT_AMPERE_HOURS]: {
    id: AlcedoUnit.MEGAVOLT_AMPERE_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAVOLT_AMPERE_HOURS'));
    },
    symbol: 'MVAh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1000000
  },
  [AlcedoUnit.VOLT_AMPERE_HOURS_REACTIVE]: {
    id: AlcedoUnit.VOLT_AMPERE_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLT_AMPERE_HOURS_REACTIVE'));
    },
    symbol: 'varh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600
  },
  [AlcedoUnit.KILOVOLT_AMPERE_HOURS_REACTIVE]: {
    id: AlcedoUnit.KILOVOLT_AMPERE_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOVOLT_AMPERE_HOURS_REACTIVE'));
    },
    symbol: 'kvarh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000
  },
  [AlcedoUnit.MEGAVOLT_AMPERE_HOURS_REACTIVE]: {
    id: AlcedoUnit.MEGAVOLT_AMPERE_HOURS_REACTIVE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAVOLT_AMPERE_HOURS_REACTIVE'));
    },
    symbol: 'Mvarh',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 3600000000
  },
  [AlcedoUnit.VOLT_SQUARE_HOURS]: {
    id: AlcedoUnit.VOLT_SQUARE_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.VOLT_SQUARE_HOURS'));
    },
    symbol: 'V²hr',
    alcedoTypeId: AlcedoTypes.AUXILIARY_ENERGY_1
  },
  [AlcedoUnit.AMPERE_SQUARE_HOURS]: {
    id: AlcedoUnit.AMPERE_SQUARE_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.AMPERE_SQUARE_HOURS'));
    },
    symbol: 'A²hr',
    alcedoTypeId: AlcedoTypes.AUXILIARY_ENERGY_2
  },
  [AlcedoUnit.JOULES_PER_HOUR]: {
    id: AlcedoUnit.JOULES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_HOUR'));
    },
    symbol: 'J/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 0.0002777777777777778
  },
  [AlcedoUnit.CUBIC_FEET_PER_DAY]: {
    id: AlcedoUnit.CUBIC_FEET_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_FEET_PER_DAY'));
    },
    symbol: 'ft³/day',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.001179869
  },
  [AlcedoUnit.CUBIC_METERS_PER_DAY]: {
    id: AlcedoUnit.CUBIC_METERS_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUBIC_METERS_PER_DAY'));
    },
    symbol: 'm³/day',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.041666667
  },
  [AlcedoUnit.WATT_HOURS_PER_CUBIC_METER]: {
    id: AlcedoUnit.WATT_HOURS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATT_HOURS_PER_CUBIC_METER'));
    },
    symbol: 'Wh/m³',
    alcedoTypeId: AlcedoTypes.PRESSURE,
    multiplier: 3600
  },
  [AlcedoUnit.JOULES_PER_CUBIC_METER]: {
    id: AlcedoUnit.JOULES_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_CUBIC_METER'));
    },
    symbol: 'J/m³',
    alcedoTypeId: AlcedoTypes.PRESSURE
  },
  [AlcedoUnit.MOLE_PERCENT]: {
    id: AlcedoUnit.MOLE_PERCENT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MOLE_PERCENT'));
    },
    symbol: 'mol%',
    alcedoTypeId: AlcedoTypes.RATIO
  },
  [AlcedoUnit.PASCAL_SECONDS]: {
    id: AlcedoUnit.PASCAL_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PASCAL_SECONDS'));
    },
    symbol: 'Pa·s',
    alcedoTypeId: AlcedoTypes.VISCOSITY
  },
  [AlcedoUnit.MILLION_STANDARD_CUBIC_FEET_PER_MINUTE]: {
    id: AlcedoUnit.MILLION_STANDARD_CUBIC_FEET_PER_MINUTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLION_STANDARD_CUBIC_FEET_PER_MINUTE'));
    },
    symbol: 'MMSCFM',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.BYTE_PER_SECOND]: {
    id: AlcedoUnit.BYTE_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BYTE_PER_SECOND'));
    },
    symbol: 'B/s',
    alcedoTypeId: AlcedoTypes.DATA_TRANSFER_SPEED
  },
  [AlcedoUnit.KILOBYTE_PER_SECOND]: {
    id: AlcedoUnit.KILOBYTE_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOBYTE_PER_SECOND'));
    },
    symbol: 'KB/s',
    alcedoTypeId: AlcedoTypes.DATA_TRANSFER_SPEED,
    multiplier: 1000
  },
  [AlcedoUnit.MEGABYTE_PER_SECOND]: {
    id: AlcedoUnit.MEGABYTE_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGABYTE_PER_SECOND'));
    },
    symbol: 'MB/s',
    alcedoTypeId: AlcedoTypes.DATA_TRANSFER_SPEED,
    multiplier: 1000000
  },
  [AlcedoUnit.BYTE]: {
    id: AlcedoUnit.BYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.BYTE'));
    },
    symbol: 'B',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME
  },
  [AlcedoUnit.KILOBYTE]: {
    id: AlcedoUnit.KILOBYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOBYTE'));
    },
    symbol: 'KB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1000
  },
  [AlcedoUnit.MEGABYTE]: {
    id: AlcedoUnit.MEGABYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGABYTE'));
    },
    symbol: 'MB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1000000
  },
  [AlcedoUnit.GIGABYTE]: {
    id: AlcedoUnit.GIGABYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGABYTE'));
    },
    symbol: 'GB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1000000000
  },
  [AlcedoUnit.TERABYTE]: {
    id: AlcedoUnit.TERABYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TERABYTE'));
    },
    symbol: 'TB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1000000000000
  },
  [AlcedoUnit.KIBIBYTE]: {
    id: AlcedoUnit.KIBIBYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KIBIBYTE'));
    },
    symbol: 'KiB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1024
  },
  [AlcedoUnit.MEBIBYTE]: {
    id: AlcedoUnit.MEBIBYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEBIBYTE'));
    },
    symbol: 'MiB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1048576
  },
  [AlcedoUnit.GIBIBYTE]: {
    id: AlcedoUnit.GIBIBYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIBIBYTE'));
    },
    symbol: 'GiB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 1073741824
  },
  [AlcedoUnit.TEBIBYTE]: {
    id: AlcedoUnit.TEBIBYTE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TEBIBYTE'));
    },
    symbol: 'TiB',
    alcedoTypeId: AlcedoTypes.DATA_VOLUME,
    multiplier: 109951162777
  },
  [AlcedoUnit.GIGAJOULE]: {
    id: AlcedoUnit.GIGAJOULE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGAJOULE'));
    },
    symbol: 'GJ',
    alcedoTypeId: AlcedoTypes.ENERGY,
    multiplier: 1000000000
  },
  [AlcedoUnit.GIGAHERTZ]: {
    id: AlcedoUnit.GIGAHERTZ,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGAHERTZ'));
    },
    symbol: 'GHz',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 1000000000
  },
  [AlcedoUnit.TERAHERTZ]: {
    id: AlcedoUnit.TERAHERTZ,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TERAHERTZ'));
    },
    symbol: 'THz',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 1000000000000
  },
  [AlcedoUnit.PER_DAY]: {
    id: AlcedoUnit.PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.PER_DAY'));
    },
    symbol: '/d',
    alcedoTypeId: AlcedoTypes.FREQUENCY,
    multiplier: 0.000011574074074074073
  },
  [AlcedoUnit.WATT_HOURS_PER_SQUARE_METER]: {
    id: AlcedoUnit.WATT_HOURS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.WATT_HOURS_PER_SQUARE_METER'));
    },
    symbol: 'Wh/m²',
    alcedoTypeId: AlcedoTypes.INSOLATION
  },
  [AlcedoUnit.MILLIJOULES_PER_SECOND]: {
    id: AlcedoUnit.MILLIJOULES_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIJOULES_PER_SECOND'));
    },
    symbol: 'mJ/s',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 0.001
  },
  [AlcedoUnit.JOULES_PER_SECOND]: {
    id: AlcedoUnit.JOULES_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_SECOND'));
    },
    symbol: 'J/s',
    alcedoTypeId: AlcedoTypes.POWER
  },
  [AlcedoUnit.KILOJOULES_PER_SECOND]: {
    id: AlcedoUnit.KILOJOULES_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES_PER_SECOND'));
    },
    symbol: 'kJ/s',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000
  },
  [AlcedoUnit.MEGAJOULES_PER_SECOND]: {
    id: AlcedoUnit.MEGAJOULES_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_SECOND'));
    },
    symbol: 'MJ/s',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000000
  },
  [AlcedoUnit.GIGAJOULES_PER_SECOND]: {
    id: AlcedoUnit.GIGAJOULES_PER_SECOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGAJOULES_PER_SECOND'));
    },
    symbol: 'GJ/s',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 1000000000
  },
  [AlcedoUnit.MILLIJOULES_PER_HOUR]: {
    id: AlcedoUnit.MILLIJOULES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIJOULES_PER_HOUR'));
    },
    symbol: 'mJ/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 2.7777777777777776e-7
  },
  [AlcedoUnit.KILOJOULES_PER_HOUR]: {
    id: AlcedoUnit.KILOJOULES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOJOULES_PER_HOUR'));
    },
    symbol: 'kJ/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 0.2777777777777778
  },
  [AlcedoUnit.MEGAJOULES_PER_HOUR]: {
    id: AlcedoUnit.MEGAJOULES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_HOUR'));
    },
    symbol: 'MJ/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 277.77777777777777
  },
  [AlcedoUnit.GIGAJOULES_PER_HOUR]: {
    id: AlcedoUnit.GIGAJOULES_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGAJOULES_PER_HOUR'));
    },
    symbol: 'GJ/h',
    alcedoTypeId: AlcedoTypes.POWER,
    multiplier: 277777.77777777775
  },
  [AlcedoUnit.DECIBELS_MILLIWATT]: {
    id: AlcedoUnit.DECIBELS_MILLIWATT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS_MILLIWATT'));
    },
    symbol: 'dBm',
    alcedoTypeId: AlcedoTypes.POWER_LEVEL
  },
  [AlcedoUnit.DECIBELS_WATT]: {
    id: AlcedoUnit.DECIBELS_WATT,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DECIBELS_WATT'));
    },
    symbol: 'dBW',
    alcedoTypeId: AlcedoTypes.POWER_LEVEL,
    multiplier: 1,
    offset: 30
  },
  [AlcedoUnit.JOULES_PER_KILOGRAM]: {
    id: AlcedoUnit.JOULES_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.JOULES_PER_KILOGRAM'));
    },
    symbol: 'J/kg',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY
  },
  [AlcedoUnit.MEGAJOULES_PER_KILOGRAM]: {
    id: AlcedoUnit.MEGAJOULES_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MEGAJOULES_PER_KILOGRAM'));
    },
    symbol: 'MJ/kg',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY,
    multiplier: 1000000
  },
  [AlcedoUnit.CENTIGRADE_DELTA]: {
    id: AlcedoUnit.CENTIGRADE_DELTA,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CENTIGRADE_DELTA'));
    },
    symbol: '°C',
    alcedoTypeId: AlcedoTypes.TEMPERATURE_DIFFERENCE
  },
  [AlcedoUnit.UNIX_TIMESTAMP]: {
    id: AlcedoUnit.UNIX_TIMESTAMP,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.UNIX_TIMESTAMP'));
    },
    symbol: 'ms',
    alcedoTypeId: AlcedoTypes.TIME
  },
  [AlcedoUnit.MILLILITERS_PER_HOUR]: {
    id: AlcedoUnit.MILLILITERS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLILITERS_PER_HOUR'));
    },
    symbol: 'ml/h',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT,
    multiplier: 0.000001
  },
  [AlcedoUnit.GIGAWATT_HOURS]: {
    id: AlcedoUnit.GIGAWATT_HOURS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.GIGAWATT_HOURS'));
    },
    symbol: 'GWh',
    alcedoTypeId: AlcedoTypes.ENERGY
  },
  [AlcedoUnit.KILOGRAMS_PER_SQUARE_METER]: {
    id: AlcedoUnit.KILOGRAMS_PER_SQUARE_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_SQUARE_METER'));
    },
    symbol: 'kg/m²',
    alcedoTypeId: AlcedoTypes.AREA_DENSITY
  },
  [AlcedoUnit.MICROAMPERES]: {
    id: AlcedoUnit.MICROAMPERES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MICROAMPERES'));
    },
    symbol: 'μA',
    alcedoTypeId: AlcedoTypes.CURRENT,
    multiplier: 0.000001
  },
  [AlcedoUnit.KILOWATT_HOURS_PER_KILOGRAM]: {
    id: AlcedoUnit.KILOWATT_HOURS_PER_KILOGRAM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS_PER_KILOGRAM'));
    },
    symbol: 'kWh/kg',
    alcedoTypeId: AlcedoTypes.SPECIFIC_ENERGY
  },
  [AlcedoUnit.KILOWATT_HOURS_PER_CUBIC_METER]: {
    id: AlcedoUnit.KILOWATT_HOURS_PER_CUBIC_METER,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOWATT_HOURS_PER_CUBIC_METER'));
    },
    symbol: 'kWh/m³',
    alcedoTypeId: AlcedoTypes.ENERGY_DENSITY
  },
  [AlcedoUnit.KILOGRAMS_PER_JOULE]: {
    id: AlcedoUnit.KILOGRAMS_PER_JOULE,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_JOULE'));
    },
    symbol: 'kg/J',
    alcedoTypeId: AlcedoTypes.EMISSION_COEFFICIENT
  },
  [AlcedoUnit.KILOGRAMS_PER_KILOWATT_HOUR]: {
    id: AlcedoUnit.KILOGRAMS_PER_KILOWATT_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOGRAMS_PER_KILOWATT_HOUR'));
    },
    symbol: 'kg/kWh',
    alcedoTypeId: AlcedoTypes.EMISSION_COEFFICIENT
  },
  [AlcedoUnit.TONS_PER_KILOWATT_HOUR]: {
    id: AlcedoUnit.TONS_PER_KILOWATT_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.TONS_PER_KILOWATT_HOUR'));
    },
    symbol: 't/kWh',
    alcedoTypeId: AlcedoTypes.EMISSION_COEFFICIENT
  },
  [AlcedoUnit.KILOLUXES]: {
    id: AlcedoUnit.KILOLUXES,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.KILOLUXES'));
    },
    symbol: 'klx',
    alcedoTypeId: AlcedoTypes.ILLUMINANCE
  },
  [AlcedoUnit.REVOLUTIONS_PER_SECONDS]: {
    id: AlcedoUnit.REVOLUTIONS_PER_SECONDS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.REVOLUTIONS_PER_SECONDS'));
    },
    symbol: 'rps',
    alcedoTypeId: AlcedoTypes.FREQUENCY
  },
  [AlcedoUnit.CUSTOM]: {
    id: AlcedoUnit.CUSTOM,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.CUSTOM'));
    },
    symbol: '',
    alcedoTypeId: AlcedoTypes.NONE
  },
  [AlcedoUnit.STANDARD_CUBIC_FEET_PER_DAY]: {
    id: AlcedoUnit.STANDARD_CUBIC_FEET_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.STANDARD_CUBIC_FEET_PER_DAY'));
    },
    symbol: 'SCFD',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.MILLION_STANDARD_CUBIC_FEET_PER_DAY]: {
    id: AlcedoUnit.MILLION_STANDARD_CUBIC_FEET_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLION_STANDARD_CUBIC_FEET_PER_DAY'));
    },
    symbol: 'MMSCFD',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.THOUSAND_CUBIC_FEET_PER_DAY]: {
    id: AlcedoUnit.THOUSAND_CUBIC_FEET_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.THOUSAND_CUBIC_FEET_PER_DAY'));
    },
    symbol: 'MCFD',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.THOUSAND_STANDARD_CUBIC_FEET_PER_DAY]: {
    id: AlcedoUnit.THOUSAND_STANDARD_CUBIC_FEET_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.THOUSAND_STANDARD_CUBIC_FEET_PER_DAY'));
    },
    symbol: 'MSCFD',
    alcedoTypeId: AlcedoTypes.VOLUME_CURRENT
  },
  [AlcedoUnit.POUNDS_MASS_PER_DAY]: {
    id: AlcedoUnit.POUNDS_MASS_PER_DAY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.POUNDS_MASS_PER_DAY'));
    },
    symbol: 'lb/d',
    alcedoTypeId: AlcedoTypes.MASS_FLOW
  },
  [AlcedoUnit.MILLIREMS]: {
    id: AlcedoUnit.MILLIREMS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIREMS'));
    },
    symbol: 'mrem',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE,
    multiplier: 0.01
  },
  [AlcedoUnit.MILLIREMS_PER_HOUR]: {
    id: AlcedoUnit.MILLIREMS_PER_HOUR,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.MILLIREMS_PER_HOUR'));
    },
    symbol: 'mrem/h',
    alcedoTypeId: AlcedoTypes.RADIATION_EQUIVALENT_DOSE_RATE,
    multiplier: 0.000002778
  },
  [AlcedoUnit.DEGREES_LOVIBOND]: {
    id: AlcedoUnit.DEGREES_LOVIBOND,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_LOVIBOND'));
    },
    symbol: '°L',
    alcedoTypeId: AlcedoTypes.NONE
  },
  [AlcedoUnit.ALCOHOL_BY_VOLUME]: {
    id: AlcedoUnit.ALCOHOL_BY_VOLUME,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.ALCOHOL_BY_VOLUME'));
    },
    symbol: '%',
    alcedoTypeId: AlcedoTypes.RATIO
  },
  [AlcedoUnit.INTERNATIONAL_BITTERING_UNITS]: {
    id: AlcedoUnit.INTERNATIONAL_BITTERING_UNITS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.INTERNATIONAL_BITTERING_UNITS'));
    },
    symbol: 'IBU',
    alcedoTypeId: AlcedoTypes.NONE
  },
  [AlcedoUnit.EUROPEAN_BITTERNESS_UNITS]: {
    id: AlcedoUnit.EUROPEAN_BITTERNESS_UNITS,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.EUROPEAN_BITTERNESS_UNITS'));
    },
    symbol: 'EBU',
    alcedoTypeId: AlcedoTypes.NONE
  },
  [AlcedoUnit.DEGREES_PLATO]: {
    id: AlcedoUnit.DEGREES_PLATO,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.DEGREES_PLATO'));
    },
    symbol: '°P',
    alcedoTypeId: AlcedoTypes.DENSITY
  },
  [AlcedoUnit.SPECIFIC_GRAVITY]: {
    id: AlcedoUnit.SPECIFIC_GRAVITY,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.SPECIFIC_GRAVITY'));
    },
    symbol: '',
    alcedoTypeId: AlcedoTypes.DENSITY
  },
  [AlcedoUnit.EUROPEAN_BREWING_CONVENTION]: {
    id: AlcedoUnit.EUROPEAN_BREWING_CONVENTION,
    get labelKey() {
      return translate(marker('ALCEDO_UNIT.EUROPEAN_BREWING_CONVENTION'));
    },
    symbol: 'EBC',
    alcedoTypeId: AlcedoTypes.NONE
  }
};
